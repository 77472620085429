import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomDropdown from "../custom-dropdown/CustomDropdown";
import {
  Box,
  Button,
  Chip,
  Pagination,
  PaginationItem,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AddIconButton,
  DeleteIconButton,
  DocumentClone,
  EditIconButton,
  EyeIconButton,
  SwapButton,
} from "../../assets/common-svg/CommonSvg";
import CustomSearchbar from "../custom-searchbar/CustomSearchbar";
import Toggle from "../toggle-switch/ToggleSwitch";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import NoDataCard from "../no-data-card";
import CustomDateRangePicker from "../custom-date-picker/CustomDateRangePicker";
import { getUserHasAccess } from "../../utils/authorities";
import { useSelector } from "react-redux";
import { visuallyHidden } from "@mui/utils";

// Find the index of the column specified by multiIndexColumn

const EnhancedTableHead = ({
  headCells,
  order,
  orderBy,
  handleSort,
  checkMultiColumnsExist,
  columnAlign,
  showActionColumn,
}) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          let multiIndex = checkMultiColumnsExist(index);
          if (index != multiIndex && columnAlign[index]?.show) {
            return (
              <TableCell
                key={index}
                align="left"
                sortDirection={orderBy === headCell ? order : false}
                className="text-[#00000080] bg-[#FAFAFA]"
              >
                <TableSortLabel
                  active={orderBy === headCell}
                  direction={orderBy === headCell ? order : "asc"}
                  onClick={() => handleSort(headCell)}
                >
                  {headCell}
                  {orderBy === headCell ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else if (!columnAlign[index]?.show) {
            return;
          }
        })}

        {showActionColumn && (
          <TableCell
            align="center"
            className="text-[#00000080] bg-[#FAFAFA]"
            key={headCells.length}
          >
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default function CustomTable({
  handleTableFilters,
  tableFilters,
  rows,
  columns,
  columnAlign,
  TableData,
  handleNavigate,
  handleButtonClick,
  handleAddButton,
  handleTableDataButton,
  handleEditButton,
  handleEditSubjectId,
  handleCloneButton,
  handleSwapButton,
  handleDeleteButton,
  paddingTop,
  DropdownBgColor,
  setAlignment,
  alignment,
  loading,
  SearchbarBgColor,
  currentPage,
  totalpage,
  handleChangePage,
  MenuList,
  handleChangeDropDown,
  dropDownValue,
  MinWidth,
  disableButton,
  handleCellClick = () => {},
  selectedDate,
  handleDateChange,
  order,
  orderBy,
  EnableSorting,
  handleSort,
}) {
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const theme = useTheme();
  const checkMultiColumnsExist = (index) => {
    let multiIndex = columnAlign[index]?.multi
      ? columns.indexOf(columnAlign[index]?.multiIndexColumn)
      : undefined;
    return multiIndex;
  };
  return (<>
    
    <div
      className={` ${paddingTop ? paddingTop : "pt-3"} ${
        TableData?.route
          ? "bg-[#F0F1F5] !shadow-none pl-3 pr-3 pb-3 capitalize"
          : "bg-none capitalize"
      }`}
    >
      <div className="flex justify-between items-center">
        {TableData?.tableHeading && (
          <Typography variant="h6">{TableData?.tableHeading}</Typography>
        )}
        {TableData?.Toggle && (
          <Toggle
            leftText={"Subject"}
            rightText={"Request"}
            toggleWidth={"300px"}
            setAlignment={setAlignment}
            alignment={alignment}
          />
        )}
        {TableData?.button &&
          TableData.buttonAuth.module &&
          getUserHasAccess(
            userAuthority,
            TableData.buttonAuth.module,
            TableData.buttonAuth.auth
          ) && (
            <Button
              variant="contained"
              color="info"
              size="large"
              className={`${disableButton ? "disableButton" : ""}`}
              onClick={handleTableDataButton}
              disabled={disableButton ? disableButton : false}
            >
              {TableData?.button}
            </Button>
          )}
      </div>
      <div className="flex flex-col sm:grid grid-cols-2 lg:grid-cols-6 gap-4 pt-2 pb-3">
        {TableData?.filters &&
          TableData?.filters?.map((filter, index) => {
            if (filter.key == "search") {
              return (
                <div key={index} className={filter.class}>
                  <CustomSearchbar
                    value={tableFilters?.searchData}
                    handleSearch={handleTableFilters}
                    BgColor={SearchbarBgColor}
                    Width={TableData?.route ? "370px" : null}
                    Placeholder={filter?.placeholder}
                  />
                </div>
              );
            } else if (filter.key == "select") {
              return (
                <div key={index} className={filter.class}>
                  <CustomDropdown
                    LabelFontSize="15px"
                    MinWidth={MinWidth}
                    fullWidthBoolean={true}
                    ShowLargeDropdown={true}
                    Height={"44px"}
                    Width={"100%"}
                    BgColor={DropdownBgColor}
                    PlaceholderText={filter?.placeholder}
                    LargeBorderRadius={true}
                    handleChange={(value) =>
                      handleChangeDropDown(value, filter?.id)
                    }
                    MenuList={MenuList || filter?.MenuList}
                    value={
                      dropDownValue ||
                      (tableFilters && tableFilters[filter?.name]) ||
                      filter?.dropDownValue
                    }
                  />
                </div>
              );
            } else if (filter.key == "date") {
              return (
                <div key={index} className={filter.class}>
                  <CustomDateRangePicker
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                  />
                </div>
              );
            } else if (
              filter.key == "button" &&
              filter?.module &&
              getUserHasAccess(userAuthority, filter?.module, filter?.auth)
            ) {
              return (
                <div key={index} className={filter.class}>
                  <Button
                    variant="contained"
                    color="info"
                    size="large"
                    onClick={handleButtonClick}
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                    }}
                  >
                    {filter?.placeholder}
                  </Button>
                </div>
              );
            }
          })}
      </div>
      <div className="bg-white shadow-sm">
        <TableContainer component={"div"}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {EnableSorting ? (
              <EnhancedTableHead
                headCells={columns}
                orderBy={orderBy}
                order={order}
                handleSort={handleSort}
                checkMultiColumnsExist={checkMultiColumnsExist}
                columnAlign={columnAlign}
                showActionColumn={TableData?.actions}
              />
            ) : (
              <TableHead className="!bg-[#FAFAFA]">
                <TableRow className="!bg-[#F6F8FC]">
                  {columns?.map((column, index) => {
                    let multiIndex = checkMultiColumnsExist(index);
                    if (index != multiIndex && columnAlign[index]?.show) {
                      return (
                        <TableCell
                          align={columnAlign[index]?.rowAlign}
                          key={index}
                          className="text-[#00000080] bg-[#FAFAFA]"
                        >
                          {column}
                        </TableCell>
                      );
                    } else if (!columnAlign[index]?.show) {
                      return;
                    }
                  })}
                  {TableData?.actions && (
                    <TableCell
                      align="center"
                      className="text-[#00000080] bg-[#FAFAFA]"
                      key={columns.length}
                    >
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            {!loading &&
              <TableBody>
                {loading ? (
                  <SkeletonLoader
                    IsActionAvilable={Boolean(TableData?.actions)}
                    columns={columnAlign}
                    rowCount={5}
                    isTable={true}
                  />
                ) : rows?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns?.length + (TableData?.actions ? 1 : 0)}
                      align="center"
                    >
                      <div className="mt-4">
                        <NoDataCard />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  rows?.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row?.map((cell, cellIndex) => {
                        let multiIndex = checkMultiColumnsExist(cellIndex);

                        if (
                          cellIndex !== multiIndex &&
                          columnAlign[cellIndex]?.show
                        ) {
                          return (
                            <TableCell
                              key={cellIndex}
                              align={columnAlign[cellIndex]?.colAlign}
                              className={`font-medium ${
                                columnAlign[cellIndex].isLineBreak
                                  ? "breakMain"
                                  : ""
                              }`}
                              style={{
                                width: columnAlign[cellIndex]?.width ?? "auto",
                              }}
                            >
                              {cell === "Completed" ||
                              cell === "Saved as Draft" ||
                              cell === "Pending" ||
                              cell === "Rejected" ? (
                                <Chip
                                  label={cell}
                                  size={"medium"}
                                  color={
                                    cell === "Pending" ||
                                    cell === "Saved as Draft"
                                      ? "warning"
                                      : cell === "Rejected"
                                      ? "error"
                                      : "success"
                                  }
                                />
                              ) : (
                                <div className="flex flex-col justify-center">
                                  {columnAlign[cellIndex]?.isCloumnClick ? (
                                    <button
                                      onClick={() => handleCellClick(row)}
                                      className="font-bold"
                                      style={{
                                        color: columnAlign[cellIndex]?.color,
                                        textDecoration:
                                          columnAlign[cellIndex]?.textDecoration,
                                      }}
                                    >
                                      {cell}
                                    </button>
                                  ) : columnAlign[cellIndex]?.tooltipBool &&
                                    row ? (
                                    row[cellIndex] !== "--" && getUserHasAccess(
                                      userAuthority,
                                      "subject_pending",
                                      columnAlign[cellIndex]?.toolAuth
                                    )? (    
                                      <Tooltip
                                        title={columnAlign[cellIndex]?.toolText}
                                        onClick={() => handleEditSubjectId(row)}
                                      >
                                        <div
                                          className="cursor-pointer underline text-sm sm:text-base font-semibold"
                                          style={{
                                            color: "rgb(53, 113, 255)",
                                          }}
                                        >
                                          {cell}
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      <span>{cell}</span>
                                    )
                                  ) : (
                                    <span>{cell}</span>
                                  )}

                                  <span className="text-[#afafaf]">
                                    {row[multiIndex]}
                                  </span>
                                </div>
                              )}
                            </TableCell>
                          );
                        } else if (!columnAlign[cellIndex]?.show) {
                          return;
                        } else {
                          return (
                            <TableCell
                              key={cellIndex}
                              align={columnAlign[cellIndex]?.colAlign}
                              className="pl-[4rem] font-medium"
                            >
                              {cell}
                            </TableCell>
                          );
                        }
                      })}

                      {rows && TableData?.actions && (
                        <TableCell align="center">
                          <div className="flex justify-center gap-[4px]">
                            {TableData?.actions?.map((action, index) => {
                              if (action?.key === "view") {
                                return (
                                  action?.module &&
                                  getUserHasAccess(
                                    userAuthority,
                                    action?.module,
                                    action.auth
                                  ) && (
                                    <div key={index}>
                                      <Tooltip title="View">
                                        <span>
                                          <IconButton>
                                            <EyeIconButton
                                              bgColor={"#3571FF"}
                                              handleOnClick={() =>
                                                handleNavigate(row)
                                              }
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                );
                              } else if (action?.key === "edit") {
                                return (
                                  action?.module &&
                                  getUserHasAccess(
                                    userAuthority,
                                    action?.module,
                                    action.auth
                                  ) && (
                                    <div
                                      key={index}
                                      className={`${
                                        row[5] && typeof row[5] == "boolean"
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                    >
                                      <Tooltip title="Edit">
                                        <span>
                                          <IconButton
                                            disabled={
                                              row[5] && typeof row[5] == "boolean"
                                            }
                                          >
                                            <EditIconButton
                                              disabled={
                                                row[5] &&
                                                typeof row[5] == "boolean"
                                              }
                                              bgColor={"#C3C3C3"}
                                              handleOnClick={() =>
                                                handleEditButton(row)
                                              }
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                );
                              } else if (action?.key === "add") {
                                return (
                                  action?.module &&
                                  getUserHasAccess(
                                    userAuthority,
                                    action?.module,
                                    action.auth
                                  ) && (
                                    <div key={index}>
                                      <Tooltip title="Add New Visit">
                                        <span>
                                          <IconButton
                                            className={
                                              (row?.[3]?.toLowerCase()?.trim() === "dead" || row?.[3]?.toLowerCase()?.trim() === "lost" || row?.[3]?.toLowerCase()?.trim() === "lost to follow up") || !row?.[13]|| !row?.[14]
                                                ? "disable-add-visit-btn"
                                                : ""
                                            }
                                          >
                                            <AddIconButton
                                              bgColor={"#00B278"}
                                              handleOnClick={() => row?.[3]?.toLowerCase()?.trim() === "dead"  || row?.[3]?.toLowerCase()?.trim() === "lost" || row?.[3]?.toLowerCase()?.trim() === "lost to follow up" || !row?.[13] || !row?.[14] ? {} : handleAddButton(row)}
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                );
                              } else if (action?.key === "clone") {
                                return (
                                  action?.module &&
                                  getUserHasAccess(
                                    userAuthority,
                                    action?.module,
                                    action.auth
                                  ) && (
                                    <div key={index}>
                                      <Tooltip title="Clone">
                                        <span>
                                          <IconButton>
                                            <DocumentClone
                                              bgColor={"#3571FF"}
                                              handleOnClick={() =>
                                                handleCloneButton(row)
                                              }
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                );
                              } else if (action?.key === "swap") {
                                return (
                                  action?.module &&
                                  getUserHasAccess(
                                    userAuthority,
                                    action?.module,
                                    action.auth
                                  ) && (
                                    <div key={index}>
                                      <Tooltip title="Swap">
                                        <span>
                                          <IconButton>
                                            <SwapButton
                                              bgColor={"#3571FF"}
                                              handleOnClick={() =>
                                                handleSwapButton(row)
                                              }
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                );
                              } else if (action?.key === "delete") {
                                return (
                                  action?.module &&
                                  getUserHasAccess(
                                    userAuthority,
                                    action?.module,
                                    action.auth
                                  ) && (
                                    <div
                                      key={index}
                                      className={`${
                                        row[5] && typeof row[5] == "boolean"
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                    >
                                      <Tooltip title="Delete">
                                        <span>
                                          <IconButton
                                            disabled={
                                              (row[5] &&
                                                typeof row[5] == "boolean") ||
                                              row[6] == true
                                            }
                                          >
                                            <DeleteIconButton
                                              bgColor={"#FA5151"}
                                              handleOnClick={() =>
                                                handleDeleteButton(row)
                                              }
                                              disabled={
                                                (row[5] &&
                                                  typeof row[5] == "boolean") ||
                                                row[6] == true
                                              }
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )
                                );
                              }
                            })}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            }
          </Table>
        </TableContainer>
      </div>
      {rows?.length != 0 && (
        <Pagination
          page={currentPage}
          onChange={(event, value) => handleChangePage(value)}
          className="pt-1 pb-3"
          color="primary"
          sx={{
            ".MuiButtonBase-root.Mui-selected": {
              backgroundColor: theme.palette.info.main,
              color: "#FFFFFF",
              borderColor: "#3571FF",
              "& hover": {
                backgroundColor: `${theme.palette.info.main}!important`,
              },
            },
            ".MuiButtonBase-root": {
              border: " 1px solid #E0E0E0",
              color: "gray",
            },
            ".css-zpc33v-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover":
              { backgroundColor: "primary" },
            color: "#5B626B",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
          variant="contained"
          count={totalpage}
          shape="rounded"
          boundaryCount={1} // Show the first and last pages
          siblingCount={1} // Show one sibling (neighboring page)
          renderItem={(item) => {
            if (item.type === "start-ellipsis" && currentPage >= 5) {
              return <PaginationItem {...item} page={1} />;
            }
            return <PaginationItem {...item} />;
          }}
        />
      )}
    </div>
    
    </>
  );
}
