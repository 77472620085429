import { useParams } from "react-router";
import HeaderBackNav from "../../components/header-back-navigation/HeaderBackNav";
import {
  AppointmentMainIcon,
  CheckGreen,
  InfoIcon,
  UserIcon,
} from "../../assets/common-svg/CommonSvg";
import CustomAccordion from "../../components/custom-accordion/CustomAccordion";
import { useEffect, useState } from "react";
import { FormView } from "../form-template/create-form-template/FormView";
import CustomDrawer from "../../components/custom-drawer/CustomDrawer";
import { FormCommentList } from "../../components/form-template/FormCommentList";
import { FormValues } from "../../components/form-template/FormValues";
import { CustomHeaderCard } from "../../components/custom-header-card/CustomHeaderCard";
import { getUserHasAccess } from "../../utils/authorities";
import { Button, Typography } from "@mui/material";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import SubjectApi from "../../services/subjectService";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import {
  UrlToRouteArrayConverter,
  calculateYears,
} from "../../utilites/Common";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import NoDataCard from "../../components/no-data-card";
import moment from "moment";
import SelectFormModel from "../../components/custom-model/SelectFormModel";
import QueryApi from "../../services/queryManage";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import CustomDropdown from "../../components/custom-dropdown/CustomDropdown";
import ConfirmationModal from "../../components/modal-contents/ConfirmationModal";
import { StatusChangeHeading } from "../../utils/common";
import CustomModel from "../../components/custom-model/CustomModel";
import {
  deadDateValue,
  FollowUpLostDateValue,
  patientIdValue,
} from "../../utils/formik/initialValues";
import {
  addPatientIdSchema,
  deathDateSchema,
  lostToFollowUpSchema,
  recordedDateSchema,
} from "../../utils/formik/validationSchema";
import SubAdminApi from "../../services/subAdminService";

export const AppointmentDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [deadValue, setDeadValue] = useState(deadDateValue)
  const [followUpValue, setFollowUpValue] = useState(FollowUpLostDateValue)
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const snackbar = useSelector((state) => state.snackBar);
  const AssignedFormDataList = useSelector(
    (state) => state?.phaseReducer.asignedFormTemplate
  );
  const [recordedDateModel, setRecordedDateModel] = useState(false);
  const [recordedDate, setRecordedDate] = useState(null);
  const phaseDetails = useSelector((state) => state?.phaseReducer?.phaseDetail);
  const [SubjectData, setSubjectData] = useState({});
  const [lifeValue, setLifeValue] = useState("");
  const [selectedLifeValue, setSelectedLifeValue] = useState("");
  const [patientId, setPatientId] = useState(""); // This would be removed when setting from api.
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openAddPatientIdModal, setOpenAddPatientIdModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [breadCrumbArray] = useState(
    UrlToRouteArrayConverter(phaseDetails, location.pathname)
  );
  const [queryLoading, setQueryLoading] = useState(false);
  const [openAppointmentModel, setOpenAppointmentModel] = useState(false);
  const [isFormLoading, SetIsFormLoading] = useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const [openConfirmStausChange, setOpenConfirmStausChange] = useState(false);
  const [formTemplateList, setFormTemplateList] = useState([]);


  const [queryData, setQueryData] = useState([
    {
      title: "Relapsed/Refractory",
      leftBorder: false,
      backgroundColor: "#F0F1F5",
      color: "#000000",
      expanded: false,
      menuData: [
        {
          title: "Aman Sharma Max Hospital (PI)",
          name: "AS",
          describe: "Is sugar test done empty stomach ?",
          date: "05/04/2024",
        },
      ],
      previous_value: [],
    },
  ]);

  const [dateModelData, setDateModelData] = useState({
    title: "Add Date",
    children: [],
  });

  const [addIdModelData] = useState({
    title: "Add Patient Id",
    children: [
      {
        type: "input",
        name: "patientId",
        label:
          "Ensure that the entered Patient ID is same as received from ImmunoACT",
        placeholder: "Enter Patient Id",
        IsRequired: true,
      },
    ],
  });

  const getSubjectDetails = async () => {
    try {
      const res = await SubjectApi.getSubjectDetails(id);
      setSubjectData(res?.data);
      setLifeValue(
        res?.data?.life_status?.toLowerCase() == "lost"
          ? "lost_to_follow_up"
          : res?.data?.life_status?.toLowerCase() || ""
      );
      setPatientId(res?.data?.patient_id);
      let accData = [];
      res?.data?.appointment_details?.map((op) => {
        let obj = {};
        obj.title = op?.title;
        if (
          op?.appointment_answer?.appointment_answer_status == "in-progress"
        ) {
          obj.status = [
            {
              icon: <InfoIcon Height={20} Width={20} Filled="#FB9D2C" />,
              text: "In Progress",
              color: "#FB9D2C",
            },
          ];
          obj.color = "#FB9D2C";
        } else if (
          op?.appointment_answer?.appointment_answer_status == "completed"
        ) {
          obj.status = [
            {
              icon: <CheckGreen Filled="#00B278" />,
              text: "Completed",
              color: "#00B278",
            },
          ];
          obj.color = "#00B278";
        } else {
          obj.status = [
            {
              icon: <InfoIcon Height={20} Width={20} Filled="#FB9D2C" />,
              text: "In Progress",
              color: "#FB9D2C",
            },
          ];
          obj.color = "#FB9D2C";
        }
        if (op?.appointment_answer?.type) {
          obj.type = op?.appointment_answer?.type;
        }
        if (op?.totalQueryCount > 0) {
          obj.status = [
            ...obj.status,
            {
              icon: (
                <div className="border-l-2 ">
                  <Typography
                    variant="body1"
                    className="ml-2 rounded-3xl text-center align-self-center  text-[0.8rem] w-[1.2rem] h-[1.2rem] text-white bg-[#FF0000]"
                  >
                    {op?.totalQueryCount}
                  </Typography>
                </div>
              ),
              text: "Query Raised",
              color: "#FF0000",
            },
          ];
        }
        obj.appointment_answer_id = op?.appointment_answer?.id;
        obj.appointment_id = op?.id;
        obj.query = 3;
        obj.leftBorder = true;
        obj.expanded = false;
        accData.push(obj);
      });
      setAccordionData(accData);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    getSubjectDetails();
  }, [!accordionData && accordionData.length == 0]);

  const handleExpansion = (index) => {
    let s1 = [...accordionData];
    if (s1[index] && s1[index].expanded !== undefined) {
      s1[index].expanded = !s1[index].expanded;
    }
    setAccordionData(s1);
  };

  const getQueryHistory = async (id, version) => {
    setQueryLoading(true);
    try {
      const res = await QueryApi.getAllQueryHistory(id, version);

      if (res?.data && res?.data.length != 0) {
        let data = res?.data.map((op) => {
          return {
            title: op.field_name,
            leftBorder: false,
            backgroundColor: "#F0F1F5",
            color: "#000000",
            expanded: false,
            menuData: op.appointment_queries_replies.map((op) => {
              return {
                title: `${op.user_name} (${op.role})`,
                name: `${op.user_name[0]}${op.user_name[1]}`,
                describe: op.query,
                date: op.created_at,
              };
            }),
            values: {
              previous_value: op.previous_answer_value,
              new_value: op.new_answer_value,
            },
          };
        });
        toggleDrawer();
        setQueryData(data);
      } else {
        setQueryData([]);
        dispatch(
          setSnackbar({ value: true, message: res?.message, color: "error" })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setQueryLoading(false);
    }
  };

  const handleChangeForm = (data, index, name) => {
    let s1 = [...accordionData];
    s1[index][name] = data;
    setAccordionData(s1);
  };

  const handleAddAppointmentModel = async (values) => {
    SetIsFormLoading(true); // Reset loading state
    setRecordedDateModel(true)
    setRecordedDate({ ...values, last_recorded_visit_date: '' })
    setDateModelData((prev) => ({
      ...prev,
      title: "Add Date",
      children: [
        {
          type: "date",
          name: "last_recorded_visit_date",
          label: "Last Recorded Visit Date",
          placeholder: "Select Date",
          minDate: false,
          maxDate: new Date(),
          IsRequired: true,
        },
      ],
    }));
    setOpenAppointmentModel(false);
    SetIsFormLoading(false); // Reset loading state
  };
  const handleRecordingDate = async (data) => {
    setButtonLoading(true)
    let updatedValues = {
      title: data.form.title,
      form_template_id: data.form.id || 0,
      phase_id: SubjectData.phase_id,
      subject_id: SubjectData.id,
      last_recorded_visit_date: data.last_recorded_visit_date
    };

    try {
      // API call to add phase
      const response = await SubjectApi.addAppointment(updatedValues);

      await getSubjectDetails();
      // window.location.reload();
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Appointment added successfully",
          color: "success",
        })
      );

    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error?.message || error || "Failed to add appointment",
          color: "error",
        })
      );
    } finally {
      setButtonLoading(false)
      setRecordedDateModel(false); // Reset loading state
    }
  }


  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleQuery = (index) => {
    let s1 = [...queryData];
    if (s1[index] && s1[index].expanded !== undefined) {
      s1[index].expanded = !s1[index].expanded;
    }
    setQueryData(s1);
  };

  const findFormTypeID = (form_template_id) =>
    AssignedFormDataList?.find(
      (item) => Number(item.type.id) === Number(form_template_id)
    )?.id;

  const handleAddAppointment = async () => {
    try {
      const res = await SubAdminApi?.formTemplateOrderwise({
        subject_id: SubjectData.id,
        phase_id: SubjectData.phase_id,
      });

      if (res?.data?.length > 0) {
        const dataUpdated =
          res?.data?.map((item) => ({
            id: item?.form_template_id || findFormTypeID(item?.id),
            title: item?.name,
          })) || [];
        setFormTemplateList(dataUpdated);
        setOpenAppointmentModel(true);
      } else {
        dispatch(
          setSnackbar({
            value: true,
            message: "Required numbers of visits have been recorded",
            color: "error",
          })
        );
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const handleSelectFormError = (error) => {
    dispatch(
      setSnackbar({
        value: true,
        message: error,
        color: "error",
      })
    );
  };

  const patientLifeMenu = [
    { id: "alive", name: "Alive" },
    { id: "dead", name: "Dead" },
    { id: "lost_to_follow_up", name: "Lost to Follow Up" },
  ];

  const handlePatientLife = async (data) => {
    setButtonLoading(true);
    let statusChangeDate =
      selectedLifeValue == "dead"
        ? moment(data?.deathDateValue).format("DD/MM/YYYY")
        : moment(data?.lostToFollowUp).format("DD/MM/YYYY");
    try {
      let payload = selectedLifeValue == "alive" || !data
        ? {
          subject_id: SubjectData?.id,
          life_status: 'alive',
        } : {
          subject_id: SubjectData?.id,
          life_status: selectedLifeValue == "dead" ? selectedLifeValue : "lost",
          status_change_date: statusChangeDate,
        }
      const res = await SubjectApi.changeLifeStatus(payload);
      console.log(res.status, "res.status");
      if (res.status) {
        getSubjectDetails();
        dispatch(
          setSnackbar({ value: true, message: res?.message, color: "success" })
        );
      } else {
        dispatch(
          setSnackbar({
            value: true,
            message: res?.message,
            color: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error changing life status:", error);
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setButtonLoading(false);
      setOpenDateModal(false);
      setDateModelData((prev) => ({ ...prev, children: [] }));
    }
  };

  const handleAddPatientId = async (data) => {
    setButtonLoading(true);
    try {
      const res = await SubjectApi.addPatientId({
        subject_id: SubjectData?.id,
        patient_id: data?.patientId,
      });
      if (res.status) {
        getSubjectDetails();
        dispatch(
          setSnackbar({
            value: true,
            message: res.message,
            color: "success",
          })
        );
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setOpenAddPatientIdModal(false);
      setButtonLoading(false);
    }
  };

  const toggleDateModal = () => {
    setOpenDateModal(true);
  };

  const handleStatusChangeConfirmation = (confirmationResult) => {
    if (confirmationResult) {
      setOpenConfirmStausChange(false);
      toggleDateModal();
    } else {
      setOpenConfirmStausChange(false);
      setSelectedLifeValue(""); // selected value to be empty when No button is clicked
      setDateModelData((prev) => ({ ...prev, children: [] })); //SetModal Children to be empty
      //Repeat the same above when api process is completed  either in response/error encounter.
    }
  };

  const handleOpenConfirmModal = (value, confirmModel) => {
    confirmModel ? setOpenConfirmStausChange(true) : value != "alive" ? toggleDateModal() : '';
    setSelectedLifeValue(value);
    if (value == 'alive') {
      handlePatientLife()
    }
    if (value == "dead") {
      if (!confirmModel) {
        setDeadValue({ deathDateValue: moment(SubjectData.status_change_date, "DD/MM/YYYY").format("MM/DD/YYYY") })
      }
      setDateModelData((prev) => ({
        ...prev,
        title: confirmModel ? "Add Date" : "Edit Date",
        children: [
          {
            type: "date",
            name: "deathDateValue",
            label: "Date of Death",
            placeholder: "Select Date",
            minDate: false,
            maxDate: new Date(),
            IsRequired: true,
          },
        ],
      }));
    } else if (value == "lost_to_follow_up") {
      if (!confirmModel) {
        setFollowUpValue({ lostToFollowUp: moment(SubjectData.status_change_date, "DD/MM/YYYY").format("MM/DD/YYYY") })
      }
      setDateModelData((prev) => ({
        ...prev,
        title: confirmModel ? "Add Date" : "Edit Date",
        children: [
          {
            type: "date",
            name: "lostToFollowUp",
            label: "Date of Last Follow Up",
            placeholder: "Select Date",
            minDate: false,
            maxDate: new Date(),
            IsRequired: true,
          },
        ],
      }));
    }
  };

  return (
    <div className="p-3 flex flex-col gap-4 mt-4">
      <BreadCrumb BreadCrumbsListing={breadCrumbArray} />
      <SelectFormModel
        open={openAppointmentModel}
        setOpen={setOpenAppointmentModel}
        isFormLoading={isFormLoading}
        data={formTemplateList}
        SetIsFormLoading={SetIsFormLoading}
        handleValue={handleAddAppointmentModel}
        handleError={handleSelectFormError}
      />
      <div className="flex flex-col lg:grid grid-cols-12 gap-4 ">
        <div className="lg:col-start-1  lg:col-end-4 sm:col-start-1 sm:col-end-13 md:col-start-1 md:col-end-13 ">
          <CustomHeaderCard
            SubjectData={{
              code: SubjectData?.subject_code,
              gender: SubjectData?.gender,
              age: calculateYears(SubjectData?.dob),
            }}
            DetailsArray={[
              { FieldName: "Patient Id", Value: patientId || "- -" },
              {
                FieldName: "Patient Status",
                icon: {
                  name: "Edit",
                  auth: 162,
                  value: lifeValue,
                  status: lifeValue == "lost_to_follow_up" || lifeValue == "dead" ? true : false,
                  type: "edit",
                  toolText: "Edit the date",
                  event: handleOpenConfirmModal
                },
                Value:
                  lifeValue == "lost_to_follow_up"
                    ? `Lost To Follow Up ${SubjectData?.status_change_date
                      ? `(${SubjectData?.status_change_date})`
                      : ""
                    }`
                    : lifeValue == "dead"
                      ? `Dead ${SubjectData?.status_change_date
                        ? `(${SubjectData?.status_change_date})`
                        : ""
                      }`
                      : lifeValue,
              },
              {
                FieldName: "Date of Birth",
                Value: moment(SubjectData?.dob).format("DD/MM/YYYY"),
              },
              {
                FieldName: "Phase",
                Value: `${SubjectData?.phase_details?.phase_code} ${SubjectData?.phase_details?.title}`,
              },
              {
                FieldName: "Editor",
                Value: SubjectData?.editor?.name || "Not Assigned",
              },
              {
                FieldName: "Principal Investigator",
                Value: SubjectData?.investigator?.name || "Not Assigned",
              },
              {
                FieldName: "Hospital Name",
                Value: SubjectData?.pi_coordinator?.hospital_details?.name || "Not Assigned",
              },
            ]}
            startIcon={<UserIcon />}
            endIcon={<AppointmentMainIcon />}
            loading={loading}
          />
        </div>
        <div className="lg:col-start-4 lg: col-end-13 sm:col-start-1 sm:col-end-13 ">
          <div>
            <div className="flex flex-col items-center  gap-3 md:gap-0 sm:flex-row  justify-content-between pb-3">
              <Typography variant="h5" className="align-self-center mt-4">
                Visit List
              </Typography>
              <div className=" w-[100%] sm:w-[100%] md:w-[70%] xl:w-[55%]">
                {loading ? (
                  <SkeletonLoader rowCount={1} height={30} isTable={false} />
                ) : (
                  <div className="flex gap-2  justify-content-end">
                    {getUserHasAccess(
                      userAuthority,
                      "appointmentManager",
                      125
                    ) && (
                        <Button
                          sx={{ height: "fit-content" }}
                          size="large"
                          className="align-self-end mr-2"
                          onClick={() => setOpenAddPatientIdModal(true)}
                          disabled={
                            SubjectData?.patient_id || lifeValue != "alive"
                          }
                        >
                          Add Patient Id
                        </Button>
                      )}
                    {getUserHasAccess(
                      userAuthority,
                      "appointmentManager",
                      126
                    ) && (
                        <CustomDropdown
                          DropDownlabel="Patient Life Status"
                          PlaceholderText={"Select Patient's Life Status"}
                          MenuList={patientLifeMenu}
                          handleChange={(e) => handleOpenConfirmModal(e.target.value, e.target.value == "alive" ? false : true)}
                          value={lifeValue}
                          PlaceholderTextMenu={true}
                          // disabled={lifeValue == "alive" ? false : true}
                          className={
                            lifeValue != "alive"
                              ? "cursor-not-allowed"
                              : "cursor-not-allowed"
                          }
                        />
                      )}
                    {getUserHasAccess(
                      userAuthority,
                      "appointmentManager",
                      122
                    ) && (
                        <Button
                          size="large"
                          className="align-self-end md:ml-2"
                          onClick={() => handleAddAppointment()}
                          disabled={
                            lifeValue !== "alive" || !SubjectData.isAddNewVisit
                          }
                          sx={{
                            cursor:
                              lifeValue === "alive" ? "pointer" : "not-allowed",
                              height: "fit-content" 
                          }}
                        >
                          Add New Visit
                        </Button>
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className="overflow-y-auto max-h-[534px]">
              {loading ? (
                <SkeletonLoader rowCount={5} height={80} isTable={false} />
              ) : accordionData.length == 0 && !loading ? (
                <div className="">
                  <NoDataCard borderRadius={"0.3rem"} />
                </div>
              ) : (
                accordionData?.map((acc, index) => (
                  <FormView
                    key={acc.appointment_id}
                    acc={acc}
                    index={index}
                    getQueryHistory={getQueryHistory}
                    handleChangeForm={handleChangeForm}
                    queryLoading={queryLoading}
                    handleExpansion={handleExpansion}
                    SubjectData={SubjectData}
                    patientLifeValue={lifeValue}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer open={open} toggleDrawer={toggleDrawer}>
        <div className="pl-4 pt-4 pr-4">
          <HeaderBackNav
            handleBackNav={toggleDrawer}
            TitleFontSize={"18px"}
            TitleFontWeight={700}
            Title={"Query History"}
          />
        </div>
        <hr />
        <div className="pl-4 pt-4 pr-4">
          {queryData?.length != 0 ? (
            queryData.map((query, index) => (
              <CustomAccordion
                key={index}
                accordionClassName={"w-[25rem] "}
                rootDivClass={"pb-3"}
                leftBorder={query?.leftBorder}
                index={index}
                expanded={query?.expanded}
                handleExpansion={handleQuery}
                color={query?.color}
                backgroundColor={query?.backgroundColor}
                title={query?.title}
              >
                <FormValues
                  leftLable={"Previous Value"}
                  leftValue={query?.values?.previous_value}
                  rightLable={"New Value"}
                  rightValue={query?.values?.new_value ?? "N/A"}
                />
                <hr className="pb-3" />
                <div>
                  <FormCommentList MenuData={query.menuData} />
                </div>
              </CustomAccordion>
            ))
          ) : (
            <div className="mt-4">
              <NoDataCard />
            </div>
          )}
        </div>
      </CustomDrawer>

      <CustomModel
        modelData={openAddPatientIdModal ? addIdModelData : dateModelData}
        open={openDateModal || openAddPatientIdModal || recordedDateModel}
        setOpen={openDateModal ? setOpenDateModal : recordedDateModel ? setRecordedDateModel : setOpenAddPatientIdModal}
        modalInitialValues={
          openDateModal
            ? selectedLifeValue == "dead"
              ? deadValue
              : followUpValue
            : recordedDateModel
              ? recordedDate
              : patientIdValue
        }
        initialValuesValidationSchema={
          openDateModal
            ? selectedLifeValue == "dead"
              ? deathDateSchema
              : lostToFollowUpSchema
            : recordedDateModel
              ? recordedDateSchema
              : addPatientIdSchema
        }
        handleModelValues={
          openDateModal ? handlePatientLife : recordedDateModel ? handleRecordingDate : handleAddPatientId
        }
        resistOverFlow={(openDateModal || recordedDateModel) ?? false} // make it conditional only for date one
        isLoadingBtn={buttonLoading}
      />

      <ConfirmationModal
        open={openConfirmStausChange}
        setOpen={setOpenConfirmStausChange}
        errMessage={StatusChangeHeading}
        handleConfirmation={handleStatusChangeConfirmation}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};
