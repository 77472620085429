import { Popover, Skeleton, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CheckButton, CrossButton } from "../../assets/common-svg/CommonSvg";
import CustomInputField from "../custom-input/CustomInput";
import { FormCommentList } from "./FormCommentList";
import { LoadingButton } from "@mui/lab";
import { getUserHasAccess } from "../../utils/authorities";
import { useSelector } from "react-redux";

const validationSchema = Yup.object({
    comment: Yup.string()
        .required("Comment is required")
        .min(2, "Comment must be at least 2 characters")
        .max(255, "Comment must be less than 255 characters"),
});

export const FormCommentMenu = ({
    isDisableQuery,
    loading,
    buttonLoading,
    open,
    handleClose,
    MenuData,
    anchorPosition,
    menuId,
    anchorReference,
    sendComment,
    setOpen,
    handleResolvedQuery,
    isResolved
}) => {
    const LoginData = useSelector((state) => state.loginData);
    const userAuthority = LoginData?.auth || [];
    const formikPage = () => {
        return (
            <Formik
                initialValues={{ comment: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    sendComment(values, resetForm);
                }}
            >
                {({ errors, setFieldValue, handleSubmit, touched, values }) => (
                    <Form className="flex flex-col gap-2.5">
                        {loading ? (
                            <Skeleton width={300} height={150} />
                        ) : (
                            <CustomInputField
                                htmlFor={"comment"}
                                IsRequired={true}
                                fullWidthBoolean={true}
                                InputId={"comment"}
                                InputType="text"
                                spacing={1}
                                onChange={(e) =>
                                    setFieldValue("comment", e.target.value)
                                }
                                MultiLine={true}
                                NoOfRows={3}
                                InputName={"comment"}
                                CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                                placeholder={"Enter the comment"}
                                LabelClasses={"text-[16px] text-[#000000]"}
                                error={Boolean(
                                    touched["comment"] && errors["comment"]
                                )}
                                touched={touched["comment"]}
                                helperText={errors["comment"]}
                                value={values["comment"]}
                                errorClasss={true}
                                disabled={false}
                            />
                        )}
                        <div className="text-center w-100">
                            {loading ? (
                                <Skeleton width={300} height={50} />
                            ) : (
                                <LoadingButton
                                    loading={buttonLoading}
                                    onSubmit={handleSubmit}
                                    className="w-100"
                                    type="submit"
                                >
                                    Submit
                                </LoadingButton>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    };
    return (
        <Popover
            id={menuId}
            anchorReference={anchorReference}
            anchorPosition={{
                top: anchorPosition.y + 135,
                left: anchorPosition.x - 200
            }}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            BackdropProps={{
                onClick: (event) => {
                    event.stopPropagation();
                },
            }}
        >
            <div
                className="bg-white flex-col justify-start p-3 gap-2.5 flex"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className="flex justify-end w-100">
                    <div className="flex gap-2">
                        {getUserHasAccess(userAuthority, "queryManager", 130) && (
                            <span className="cursor-pointer">
                                {loading && !isResolved ? (
                                    <Skeleton
                                        variant="circular"
                                        width={20}
                                        height={20}
                                    />
                                ) : !isResolved && MenuData?.length != 0 ? (
                                    <div onClick={() => handleResolvedQuery()}>
                                        <CheckButton />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </span>
                        )}
                        <span className="cursor-pointer">
                            {loading ? (
                                <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                />
                            ) : (
                                <div onClick={() => setOpen(false)}>
                                    <CrossButton />
                                </div>
                            )}
                        </span>
                    </div>
                </div>
                <div className="self-stretch h-[0px] border border-neutral-200"></div>
                {getUserHasAccess(userAuthority, "queryManager", 127) &&
                    (MenuData?.length != 0
                        ? <FormCommentList MenuData={MenuData} loading={loading} />
                        : (
                            <div className="mt-4 min-h-[4rem] min-w-[12rem] text-center">
                                <Typography variant="body2">No Query Raise</Typography>
                            </div>
                        ))}
                {getUserHasAccess(userAuthority, "queryManager", 128) ? (isDisableQuery
                    ? ""
                    : formikPage()) : ""}


            </div>
        </Popover>
    );
};
