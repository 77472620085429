import { Card, CardActionArea, CardContent, CardMedia, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import PropTypes from "prop-types";
import { getUserHasAccess } from '../../utils/authorities';
import { useSelector } from 'react-redux';

export const CustomHeaderCard = ({ startIcon, endIcon, DetailsArray, loading, SubjectData }) => {
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const theme = useTheme();
  return (
    <Card sx={{ maxWidth: { xs: "100%", md: 345 } }}
      className='pointer-events-none'
    >
      <CardActionArea>
        <CardContent>
          <div className="flex gap-2 md:gap-4">
            <div>
              {loading ? (
                <Skeleton variant="circular" width={80} height={80} />
              ) : (
                startIcon
              )}
            </div>
            <div className="flex flex-col">
              {loading ? (
                <Skeleton width={200} height={20} />
              ) : (
                <Tooltip title={SubjectData?.code || ''} placement="top">
                  <span>
                    <Typography
                      className="sub-code w-[90%]  !break-all"
                      variant="body1"
                      color="secondary"
                    >
                      {SubjectData?.code}
                    </Typography>
                  </span>
                </Tooltip>
              )}
              {loading ? (
                <Skeleton width={100} height={20} />
              ) : (
                <Typography className="text-sm md:text-base" variant="h5">
                  {SubjectData?.name}
                </Typography>
              )}
              {loading ? (
                <Skeleton width={80} height={20} />
              ) : (
                <Typography
                  variant="body1"
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {SubjectData?.gender} ({SubjectData?.age}){" "}
                </Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 pt-3 pl-1 capitalize">
            {DetailsArray.map((pair) => {
              return (
                <p className="flex flex-col" key={pair.FieldName}>
                  <Typography variant="body1" color={theme.palette.grey[400]}>
                    {pair.FieldName}
                  </Typography>
                  {loading ? (
                    <Skeleton width={150} height={20} />
                  ) : (
                    <Typography variant="body1" className='flex gap-2 align-items-center'>
                      {pair.Value}
                      {pair?.icon && pair?.icon?.status && getUserHasAccess(
                        userAuthority,
                        "subject_pending",
                        pair?.icon?.auth
                      ) && (
                          <Tooltip
                            className='pointer-events-all'
                            title={pair?.icon?.toolText}
                            onClick={() => pair?.icon?.event(pair?.icon.value, false)}
                          >
                            <div
                              className="cursor-pointer underline text-sm sm:text-base font-semibold"
                              style={{
                                color: "rgb(53, 113, 255)",
                                cursor: "pointer",
                                pointerEvents: "painted"
                              }}
                            >
                              {pair?.icon.name}
                            </div>
                          </Tooltip>)}
                    </Typography>
                  )
                  }
                </p>
              );
            })}
          </div>
        </CardContent>
        <CardMedia className="p-3">{endIcon}</CardMedia>
      </CardActionArea>
    </Card >
  );
}
CustomHeaderCard.proptypes = {
  main_heading_class: PropTypes.string,
  sub_heading_class: PropTypes.string,
  DetailsArray: PropTypes.array,
  NoWrap: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node
}